import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Content from 'components/new/content'
import Button from 'components/button/button'
import Spacer from 'components/new/spacer'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FaPlay } from '@react-icons/all-files/fa/FaPlay'
import AdditionalResources from 'components/new/additional-resources'
import {
  planterSetupUrl,
  amsBasicsUrl,
  operationsCenterUrl,
  starfireSoftwareUpdatesUrl,
} from 'data/clinics/planter-clinic-2021'

const planterSetupVideos = [
  { title: 'Importance of Proper Closing', link: `${planterSetupUrl}?videoId=D9DVdqezolQ` },
  { title: 'Seed Placement and Down Force', link: `${planterSetupUrl}?videoId=4NN7d-IANAk` },
  {
    title: 'Soybean Emergence and Common Problems',
    link: `${planterSetupUrl}?videoId=YFUPFeEXgMo`,
  },
  {
    title: 'Sidewall Compaction Issues Scouting Corn',
    link: `${planterSetupUrl}?videoId=khJebOGiVqo`,
  },
  { title: 'How to Perform Corn Stand Counts', link: `${planterSetupUrl}?videoId=opmoZUnzAVw` },
]

const amsBasicsVideos = [
  { title: 'Layout Manager - Gen 2 and Gen 3', link: `${amsBasicsUrl}?videoId=VZCGhi_Ea0g` },
  { title: 'Layout Manager - Gen 4', link: `${amsBasicsUrl}?videoId=3-0-1Ss42yE` },
  { title: 'TCM Calibration', link: `${amsBasicsUrl}?videoId=eBI1nlav20c` },
  {
    title: 'Machine and Planter Measurements for Section Control on 2630',
    link: `${amsBasicsUrl}?videoId=sOhB1muz7TQ`,
  },
  { title: 'Section Control - Gen 4', link: `${amsBasicsUrl}?videoId=NOcS4yb5M-E` },
  { title: 'TCM Calibration Using a 2630 Display', link: `${amsBasicsUrl}?videoId=wFqm9qJecO8` },
]

const operationsCenterVideos = [
  {
    title: 'Accepting New Terms and Conditions',
    link: `${operationsCenterUrl}?videoId=T63fMnJm45s`,
    required: true,
  },
  {
    title: 'How to Setup a New MyJohnDeere Account',
    link: `${operationsCenterUrl}?videoId=lDMf_qs3lCo`,
  },
  {
    title: 'Adding Boundaries into Operations Center',
    link: `${operationsCenterUrl}?videoId=v9FMtzhh-bc`,
  },
  {
    title: 'Loading Data into Operations Center',
    link: `${operationsCenterUrl}?videoId=b47lm2IYIjk`,
  },
  {
    title: 'Files and MyJohnDeere Data Manager',
    link: `${operationsCenterUrl}?videoId=WI8CuAiPhAM`,
  },
  { title: 'New Operations Center Layout', link: `${operationsCenterUrl}?videoId=DMsnPzyT7wo` },
]

const starfireSoftwareUpdatesVideos = [
  {
    title: 'SF3000 SF6000 Update Through 2630 - Prepare USB Thumb Drive',
    link: `${starfireSoftwareUpdatesUrl}?videoId=CbsgBgHAeCY`,
  },
  {
    title: 'Installing SF3000 SF6000 Update Through a 2630',
    link: `${starfireSoftwareUpdatesUrl}?videoId=r0SARnWgJ6I`,
  },
  {
    title: 'SF3000 SF6000 Update Through Gen 4 - Prepare USB Thumb Drive',
    link: `${starfireSoftwareUpdatesUrl}?videoId=eAE7y8M4VdI`,
  },
  {
    title: 'SF3000 SF6000 Data Port Update - Prepare USB Thumb Drive',
    link: `${starfireSoftwareUpdatesUrl}?videoId=TCbUHSp-jD4`,
  },
  {
    title: 'Installing SF6000 Update - Data Port',
    link: `${starfireSoftwareUpdatesUrl}?videoId=YmclCRDynoM`,
  },
  {
    title: 'Installing SF3000 SF6000 Update Through Gen 4',
    link: `${starfireSoftwareUpdatesUrl}?videoId=HBvXEaVh3U4`,
  },
]

const PlanterClinic2021 = props => {
  const {
    data: { displayImage, heroImage, operationsCenterImage, receiverImage, rowUnitImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Planter Clinic 2021 | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Virtual Planter Clinic'
        overlayOpacity={0.3}
      />

      <Content>
        <Grid>
          <Column>
            <GatsbyImage image={rowUnitImage.childImageSharp.gatsbyImageData} alt='Row unit' />
          </Column>
          <Column>
            <H2>Setting Your Planter and Assessing Stands</H2>
            <P>
              In this session, you'll get some tips on planter adjustments to check in the field and
              why these settings could be the difference in a good and bad stand. Also, learn how to
              assess your stand in the field and identify common problems.
            </P>
            <Spacer size='s' />
            <ButtonLink to={planterSetupUrl}>View course</ButtonLink>
            <Spacer size='m' />
            <H3>Lessons</H3>
            <Spacer size='s' />
            <VideoList>
              {planterSetupVideos.map(video => (
                <li key={video.title}>
                  <VideoLink to={video.link}>
                    <PlayIcon>
                      <FaPlay aria-hidden='true' focusable='false' role='presentation' />
                    </PlayIcon>
                    <span>{video.title}</span>
                  </VideoLink>
                </li>
              ))}
            </VideoList>
          </Column>
        </Grid>
        <Spacer size='xl' variable />
        <Hr />
        <Spacer size='xl' variable />
        <Grid>
          <Column>
            <GatsbyImage image={displayImage.childImageSharp.gatsbyImageData} alt='AMS Display' />
          </Column>
          <Column>
            <H2>AMS Basics</H2>
            <P>
              Get a refresher on common settings in your display. These tips will help get your
              planting season off to a smooth start and optimize your tractor and planter
              technology.
            </P>
            <Spacer size='s' />
            <ButtonLink to={amsBasicsUrl}>View course</ButtonLink>
            <Spacer size='m' />
            <H3>Lessons</H3>
            <Spacer size='s' />
            <VideoList>
              {amsBasicsVideos.map(video => (
                <li key={video.title}>
                  <VideoLink to={video.link}>
                    <PlayIcon>
                      <FaPlay aria-hidden='true' focusable='false' role='presentation' />
                    </PlayIcon>
                    <span>{video.title}</span>
                  </VideoLink>
                </li>
              ))}
            </VideoList>
          </Column>
        </Grid>
        <Spacer size='xl' variable />
        <Hr />
        <Spacer size='xl' variable />
        <Grid>
          <Column>
            <GatsbyImage
              image={operationsCenterImage.childImageSharp.gatsbyImageData}
              alt='Operations Center'
            />
          </Column>
          <Column>
            <H2>Getting Started in MyJohnDeere Operations Center</H2>
            <P>
              This set of videos will walk you through the process of setting up your MyJohnDeere
              account, along with introductions into basic functionality of Operations Center. After
              you watch this session, you will be ready to start using your data to help make future
              decisions on your farm.
            </P>
            <Spacer size='s' />
            <ButtonLink to={operationsCenterUrl}>View course</ButtonLink>
            <Spacer size='m' />
            <H3>Lessons</H3>
            <Spacer size='s' />
            <VideoList>
              {operationsCenterVideos.map(video => (
                <li key={video.title}>
                  <VideoLink to={video.link}>
                    <PlayIcon>
                      <FaPlay aria-hidden='true' focusable='false' role='presentation' />
                    </PlayIcon>
                    <span>{video.title}</span>
                  </VideoLink>
                </li>
              ))}
            </VideoList>
          </Column>
        </Grid>
        <Spacer size='xl' variable />
        <Hr />
        <Spacer size='xl' variable />
        <Grid>
          <Column>
            <GatsbyImage
              image={receiverImage.childImageSharp.gatsbyImageData}
              alt='StarFire Receiver'
            />
          </Column>
          <Column>
            <H2>How to Update Receivers with the StarFire 20-2 software</H2>
            <P>
              Learn how to update your StarFire 6000 and StarFire 3000 receivers with software
              version 20-2. This update is mandatory and will allow your receivers to continue
              operating into the future. If this update has not been completed your receivers will
              not be able to receive the StarFire signal and will only operate in WAAS.
            </P>
            <Spacer size='s' />
            <ButtonLink to={starfireSoftwareUpdatesUrl}>View course</ButtonLink>
            <Spacer size='m' />
            <H3>Lessons</H3>
            <Spacer size='s' />
            <VideoList>
              {starfireSoftwareUpdatesVideos.map(video => (
                <li key={video.title}>
                  <VideoLink to={video.link}>
                    <PlayIcon>
                      <FaPlay aria-hidden='true' focusable='false' role='presentation' />
                    </PlayIcon>
                    <span>{video.title}</span>
                  </VideoLink>
                </li>
              ))}
            </VideoList>
          </Column>
        </Grid>
      </Content>
      <AdditionalResources
        resources={[
          {
            title: 'Corn Studies',
            links: [
              <a href='/clinics/studies/Hutson_2020_Corn_2x2.pdf'>2020 Corn 2x2 Study</a>,
              <a href='/clinics/studies/Hutson_2020_Corn_Downforce_Study.pdf'>
                2020 Corn Downforce Study
              </a>,
              <a href='/clinics/studies/Hutson_2020_Corn_Planting_Depth_Study.pdf'>
                2020 Corn Planting Depth Study
              </a>,
              <a href='/clinics/studies/Hutson_2020_Corn_Sidedress_Study.pdf'>
                2020 Corn Sidedress Study
              </a>,
            ],
          },
          {
            title: 'Soybean Studies',
            links: [
              <a href='/clinics/studies/Hutson_2020_Soybean_KTS_Study.pdf'>
                2020 Soybean KTS Study
              </a>,
              <a href='/clinics/studies/Hutson_2020_Soybean_Planting_Depth_Study.pdf'>
                2020 Soybean Planting Depth Study
              </a>,
              <a href='/clinics/studies/Hutson_2020_Soybean_Population_Study.pdf'>
                2020 Soybean Population Study
              </a>,
              <a href='/clinics/studies/Hutson_2020_Soybean_Singulation_Study.pdf'>
                2020 Soybean Singulation Study
              </a>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  display: block;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: row-reverse;
  }
`

const Column = styled.div`
  width: 100%;

  :first-child {
    margin-bottom: 16px;
    max-width: 360px;
  }

  @media (min-width: 900px) {
    :first-child {
      margin-bottom: 0;
      margin-left: 24px;
      max-width: none;
      min-width: 360px;
      width: auto;
    }

    :last-child {
      flex-grow: 2;
      width: auto;
    }
  }
`

const VideoList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 12px;
    padding: 0;
  }
`

const VideoLink = styled(Link)`
  color: #000;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;

  :hover,
  :active {
    text-decoration: underline;
  }
`

const PlayIcon = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.g400};
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin-right: 12px;
  width: 24px;

  svg {
    color: #fff;
    font-size: 8px;
  }
`

const ButtonLink = props => {
  return <Button as={StyledButtonLink} color='green' ghost {...props} />
}

const StyledButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

const Hr = styled.hr`
  background-color: ${props => props.theme.color.n30};
  border: none;
  height: 1px;
  width: 100%;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "clinics/planter-clinic-2021-hero.jpg" }) {
      ...FullWidthImage
    }
    rowUnitImage: file(relativePath: { eq: "clinics/row-unit.jpg" }) {
      ...SharpImage360
    }
    displayImage: file(relativePath: { eq: "clinics/display.jpg" }) {
      ...SharpImage360
    }
    receiverImage: file(relativePath: { eq: "clinics/receiver.jpg" }) {
      ...SharpImage360
    }
    operationsCenterImage: file(relativePath: { eq: "clinics/operations-center.jpg" }) {
      ...SharpImage360
    }
  }
`

export default PlanterClinic2021
